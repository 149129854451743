// Here you can add other styles

// --- misc ---

body {
background: #fff;
}

a { color: #299e33; }
a:hover { color: #444; }
.page-link { color: #299e33; border-color: rgba(41, 158, 51, 0.5); }
.page-item.active .page-link { background-color: #299e33; border-color: rgba(41, 158, 51, 0.5); }
.page-item.disabled .page-link { color: #CCC; border-color: rgba(41, 158, 51, 0.2); }

.tab-content.p-0 > .tab-pane {
	padding: 0 !important;
}
.tab-content {
border: 0;
}
.form-control2 {
    display: block;
    width: 1%;
    flex: 1 1 auto;
    height: calc(1.5em + 0.75rem -6px);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.app-body,
.header-fixed .app-body {
    margin-top: 96px;
}

/*
.row.form-group {
    align-items: center;
}
*/
.google-places-autocomplete {
    border: none;
  }

  .google-places-autocomplete__suggestion {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
  }

  .google-places-autocomplete__input {
    display: block;
    width: 100%;
    flex: 1 1 auto !important;
    margin-bottom: 0;      
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0 0 0 0 !important;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;    
  }


.btn-secondary:focus, .btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.show > .btn-secondary.dropdown-toggle:focus {
  outline: none; 
  box-shadow:none;
}

.selectwindows {
    width: 27%;

}

.btn-space {
    margin-left: 5px;
    margin-top: 7px;
    color: white !important;
}

.btn-search {
    margin-right: 10px;
    margin-top: 5px;
}

.react-bs-table-pagination {
  
  margin-left: 5px;
  margin-right: 5px;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.container-fluid {
   /* max-width: 1600px;*/
    margin-left: 0;
}

.card {
/*box-shadow: 1px 0px 10px rgba(0,0,0,.2);*/
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
min-height: calc(100% - 1.5rem); /* fix same height boxes */
}

.card-body.white {
	background:white;
	border-radius: 0 0 0.25rem 0.25rem;
}

h5.card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
	line-height: 1.26em;
}

.card-accent-primary {
	border-top-width:4px;
}

.grid {
	padding-left:30px;
	padding-right:30px; 
}
.grid > .row > div {
	white-space:nowrap;
	padding: 5px;
}
.grid-head {
	border-bottom: 1px solid rgba(84, 182, 198, 0.7);
	margin-bottom: 15px;
}

.sw-ranking .brand {
overflow:hidden;
text-overflow: ellipsis;
}

/*--- sidebar ---*/
.sidebar {
	background: #191919; 
	
}

.sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh - 96px);
}

.sidebar .nav {
	border-left: 5px solid #474747;
}


.sidebar .nav-link.active {
  /* background: #204042; */
	background: none;
	border-left: 5px solid #00cb00;
    margin-left: -5px;
}


.sidebar .nav-dropdown.open ul .nav-link {
    color: #70adb1;
}

.sidebar .nav-link.active .nav-icon {
    color: #fff;
}


.sidebar .badge.badge-info {
    color: white;
    border-radius: 0;
    background: #00cb00;
    padding: 3px 5px;
	position: absolute;
    right: 7px;
    margin-top:3px;
    font-size:10px;
}

.sidebar .nav-link:hover {
	background: rgba(255,255,255,.05);
	color: white !important;
	border-left: 5px solid #00cb00; 
    margin-left: -5px;
}


/* ------------------ */


.removeOverflow {
    overflow: inherit !important;
}

.app-header {
background-image: url('/assets/img/top-bg.jpg');
background-size:cover;
background-color:#1f484f;
border-bottom-color:#204042;
height: 96px;
box-shadow: 0px 3px 7px rgba(0,0,0,.3);
 }
 
 .app-header {
      a.navbar-brand {
			margin-top: 0px;
			width: 245px;
					
			img {
					position:absolute;
					top:18px;
				}
			}
      }
 
 .app-header a {
 color:white !important;
 }

 
 a.navbar-brand {
    margin-top: 15px;
    margin-left: 40px;
}


/*-----*/
.btn, .badge, .form-control, .page-item .page-link, .nav-tabs .nav-link {
border-radius:0px !important;
}

.bg-primary {
	background:#118d94 !important;
}
.btn-primary, .btn-success {
	background:#299e33 !important;
	
}
.btn-danger {
	background-color: #f20000 !important;
	
}

.sign_up_text {
	font-size:16px;
	padding: 5px 20px; 
}

.bg-white {
background: white !important; 
}

.border1px {
	border-width: 1px !important;
}

.borderless {
border: 0 !important;
} 

/*-----*/
.webcam-preview {
	display: flex;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #777;
	max-width: 226px;
	max-height: 226px;
}

/*-----*/

.breadcrumb {
	display:none; 
}

main nav {
    height: 30px;
    display: block;
}

.card-header, .card-footer {
background:#FFF
}

.form-control {
border-color: #c8ced3
}

.card-header {
border-bottom: 1px solid #abb1b5;
}

.table .thead-light th, .table th, .table td, .card-footer {
	border-color: #abb1b5;
}

.card-subheader { 
	padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #abb1b5;
	border-top:1px solid #fff;
}

.card-subheader .form-check {
	display:flex; 
}
 
.card-subheader .form-check {
	display:flex; 
}
.card-subheader .form-check .form-check-input {
	margin-left: 0;
}

.card-subheader .form-check .form-check-label {
	margin-left: 1.25em;
}


.card {
	border-color: #299e33; 
	border-top-width: 4px !important;
    border-bottom-width: 4px !important;
}

.nav-tabs .nav-link.active {
    border: none;
    box-shadow: 0px -5px 0 inset #299e33;
}

/*
.no-margin {
    padding: 0 !important;
}

.no-padding {
    padding: 0 !important;
}
*/


/* logo */

.logo {
	img {
	/*	width:233px;
		height:61px; */
		display:block;
	}

	h1 {
		font-size:1.5em;
		color: #187da0; /* poner primary */
	}
	h2 {
		font-size:1.1em;
		text-transform:uppercase;
		font-weight:normal;
		color: #484848;
	}
}


/* dropdowns custom */
.dropdown.custom {
	.btn {
		text-align: left;
		background: white;
		padding-right: 30px;
	}
	
	.dropdown-toggle::after {
		position:absolute;
		right: 10px;
		top: 15px;
	}
}


.card-header .dropdown.custom {

	.btn {
    padding: 0px 30px 0px 10px;
    font-size:13px;
	}
	
	.dropdown-toggle::after {
    top:11px;
	}
}


/*-----------*/

@include media-breakpoint-up(lg) {
	.app-header {
      a.navbar-brand {	
			img {
					/*position:absolute;
					top:13px;*/
					margin-left:-17px;
				}
			}
      }
	  
}

/* --------------------------*/ 
/*
a.show-info {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
	margin: 5px 0 0 10px;
	background-repeat:no-repeat;
	background-size:cover;
}

.sensor.card a.show-info {
	background-image:url('/assets/img/sensor-show-info.png');
}

.store.card a.show-info {
	background-image:url('/assets/img/store-show-info.png');
}

.product.card a.show-info {
	background-image:url('/assets/img/product-show-info.png');
}

.alarm.card a.show-info {
	background-image:url('/assets/img/alarm-show-info.png');
}


.alpha_notice {
	background: #741868;
	padding: 10px ; 
	width: 80%;
	margin:auto;
	position: relative;
      display: block;
      z-index: 2;
	  line-height:20px;
	
}
.alpha_notice > div {
	background: #741868;
	position:absolute;
    top:-30px;
    left:-30px;
    right:-30px;
    bottom:-30px;
	height:100%;
		 -webkit-transform: skewX(20deg);
       -moz-transform: skewX(20deg);
         -o-transform: skewX(20deg);
	
}


.alpha_notice:before {
	content: "";
	 width         : 0;
    height        : 0;
    border-left   : 10px solid transparent;
    border-right  : 0px solid transparent;  
    border-bottom : 10px solid #118d94;
	position:absolute;
	bottom:0;
	right:10px;
	
}
.alpha_notice:after {
    content: " ";
    position: absolute;
    display: block;
    width: 30px;
    height: 100%;
    top: 0;
    right: -30px;
    z-index: -1;
    background: #741868;
    -ms-transform: skew(-10deg, 0deg);
    -webkit-transform: skew(-10deg, 0deg);
    transform: skew(-10deg, 0deg) translateX(-50%);
	font-size:13px;
}


.alpha_notice span {
    position: absolute;
    width: calc(100% + 15px);
    background: #741868;
    height: 10px;
    top: 0;
    left: 5px;
    margin-top: -1px;
  
    transform: skew(-10deg, -0.5deg);
    z-index: -1;
}
*/






/* attraction ranking widget */
/*
.product-attraction-item {
	
	text-align:center;
	line-height:1;
	
	img {
		width:100%;
		width:calc(100% + 2px);
		margin-left:-1px;
		border:1px solid #c0c0c0;
		margin-bottom:-5px;
	}
	
	strong {
		display: block;
		font-size: 1.2em;
		margin: 15px 25px 0 10px;
		padding-right: 0;
		padding: 5px 0 7px 15px;
		width: auto;
		white-space: nowrap;
	}
	
}
*/

/* --- .dashboard-profiles --- */
/*

.dashboard-profiles.card {
background-repeat: no-repeat;
	background-position: right 45px;
    background-position: calc(100% - 5px) 40px;
    background-size: 175px;;
}
.dashboard-profiles.card ul {
    list-style: none;
    padding-left: 5px;
    font-size: 16px;
}

.dashboard-profiles.card ul li {
    line-height:34px;
}


.dashboard-profiles.card.bg-stats-icon {
	background-image:url('/assets/img/bg-icons/stats.png');
}

.dashboard-profiles.card.bg-store-icon {
	background-image:url('/assets/img/bg-icons/store.png');
}

.dashboard-profiles.card.bg-barcode-icon {
	background-image:url('/assets/img/bg-icons/barcode.png');
}

.dashboard-profiles.card.bg-world-icon {
	background-image:url('/assets/img/bg-icons/world.png');
}

.dashboard-profiles.card.bg-wand-icon {
	background-image:url('/assets/img/bg-icons/wand.png');
}

.dashboard-profiles.card.bg-share-icon {
	background-image:url('/assets/img/bg-icons/share.png');
}
*/

/* --- oblique boxes --- */ 
/*
.oblique {
	background: black;
    padding: 10px;
    width: calc(100% - 15px);
    margin: auto;
    position: relative;
    display: block;
    z-index: 2;
    line-height: 20px;
    border: none;
    margin-bottom: 10px;
    border-radius: 0 !important;
    margin-left: 0px;
	color: white;
}

.oblique.extra:before {
	content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 8px solid white;
    position: absolute;
    bottom: 0;
    right: 20%;
}

.oblique:after {
    content: " ";
    position: absolute;
    display: block;
    width: 30px;
    height: 100%;
    top: 0;
    right: -30px;
    z-index: -1;
    background: black;
    -webkit-transform: skew(-10deg, 0deg);
    transform: skew(-10deg, 0deg) translateX(-50%);
    font-size: 13px;
}

*/

/* --- //oblique boxes --- */ 
/* --- oblique colors --- */ 
/*
.oblique.color1, .oblique.color1:after {
	background: #c7aede;
}

.oblique.color2, .oblique.color2:after {
	background: #aa87cb;
}

.oblique.color3, .oblique.color3:after {
	background: #6d4592;
}

.oblique.color4, .oblique.color4:after {
	background: #49216f;
}


.sensor.card .oblique:before {
    border-bottom-color: #f3edf8 !important;
}



.oblique.dark-blue, .oblique.dark-blue:after {
	background: #1e7a89;

}

.oblique.light-blue, .oblique.light-blue:after {
	background: #54b6c6;
}

.oblique.orange, .oblique.orange:after {
	background: #ff7800;
}


.attraction-ok .product-attraction-item .oblique, 
.attraction-ok .product-attraction-item .oblique:after {
    background: #22a200;
}

.attraction-ko .product-attraction-item .oblique, 
.attraction-ko .product-attraction-item .oblique:after {
    background: #ff3000;
}

*/

/* --- //oblique boxes --- */ 

/* --- badge --- */ 
/*
.badge-store {
    color: #fff;
    background-color: #54b6c6;
}
*/


/* --- badges --- */ 

/*

.record-filter {
    font-size: 14px;
}
.record-filter i {
    font-size: 10px;
}

.filter-tag {
    padding: 12px;
    font-weight:normal;
	margin-right:15px;
	margin-right:10px;
}

.filter-tag a {
    color:white;
    padding: 0 0 0 6px 
}


.order-more-sensors .img {
height:60px;
}


.automatic-alerts .alarms.row {
    display: table;
    width: 100%;
	border-bottom: 1px solid #b5cbcc
}
.automatic-alerts .alarms.row:last-child {
	border-bottom: none;
}
*/

/* fix 1px chrome bug */
/*
.automatic-alerts .alarms.row:last-child .col.color {
    border-radius: 0 0 0 0.25em;
  }
	
.automatic-alerts .alarms.row .col {
    display: table-cell;
    width:auto;
	vertical-align:middle;
}

.automatic-alerts .alarms.row .col.color {
    width:50px;
	color:white;
	font-weight:bold;

}

.automatic-alerts .alarms.row .col.icon {
    width:80px;
}

.automatic-alerts .alarms.row .col.time {
    width:125px;
}

.automatic-alerts .alarms.row .col.label {
    width:150px;
}

.automatic-alerts .alarms.row .info strong, 
.automatic-alerts .alarms.row .info i,
.automatic-alerts .alarms.row .info small {
    display:block;
	color: #32595a;
}


.automatic-alerts .alarms.row .info strong {
    font-size:1.20em;
    line-height:1.2em;
}
.automatic-alerts .alarms.row .info i {font-size:1.20em;  line-height:1.2em;}
 .automatic-alerts .alarms.row .info small {  font-size:1.00em;  line-height:1.2em; }

.automatic-alerts .alarms.row .col.user {
    width:100px;
}

.automatic-alerts .color.default {
	background: #32595a;
}
.automatic-alerts .color.sensor {
	background: #6d4592;
}
.automatic-alerts .color.store {
	background: #ff7800;
}

.status.oblique {
	color: #32595a;
	padding-left:20px;
	text-align:center;
	white-space:nowrap;
}
.status.pending, .status.pending:after {
	background: #d5d5d5; 
}

.status.assigned, .status.assigned:after {
	background: #ffc28b; 
}

.status.in-progress, .status.in-progress:after {
	background: #8bc7ff; 
}

.status.ignored, .status.ignored:after {
	background: #ff8b8b; 
}
*/


/*-----------*/

/* striped tables with no border-top*/
/*
.table-striped.bt-0 thead th {
    border-top: none;
}
.table-striped.bb-1 tr:last-child td {
    border-bottom:1px solid #abb1b5;
}
*/
/*-----------*/
/*
.list-of-stores td {
font-size: 0.8rem
}
.list-of-stores th {
	vertical-align:middle !important;
}
.list-of-stores th, .list-of-stores td {
text-align:center;
}
*/
/*-----------*/
/*
.btn.btn-sensor {
padding: 2px 6px;
font-size: 0.78rem;
border-radius:0;
}

.btn.btn-sensor.btn-sensor-active { background: #168100 !important}
.btn.btn-sensor.btn-sensor-finished { background: #454545 !important}
.btn.btn-sensor.btn-sensor-setup { background: #379EA8 !important}
.btn.btn-sensor.btn-sensor-stopped { background: #FF8A00 !important}
.btn.btn-sensor.btn-sensor-cancelled { background: #FE0000 !important}
*/

/*-----------*/
/*
a.fa.fa-close.red-circle {
    background: red;
    border-radius: 50px;
    width: 15px;
    text-align: center;
    height: 15px;
    color: white;
    line-height:15px;
    padding:0px;
    position: relative;
    position: absolute;
    margin: 3px;
}

a.fa.fa-close.red-circle:before {
    font-size:10px;
    position: absolute;
    left: 4px;
}
a.fa.fa-close.red-circle:hover {
	opacity: 0.5;
}
*/
/*-----------*/
/*
a.black {
	color: black !important;
}
*/
/*-----------*/

/*
form.simple-form .form-container {
	background:#dedede;
}

form.simple-form input {
	border-radius: 1px;
	border-color: #aaa;
	color: #777;
}

.simple-form .btn {
    border-radius: 1px;
    background: white !important;
    color: #076f76;
}

*/
/*-----------*/

/*https://codepen.io/mohitmanuja/pen/snEzf*/
/*
.card {
	position:relative;
}

.peel {
    width: 180px;
    height: 100px;
    padding-top: 80px;
    position: absolute;
    border-radius: 0;
    text-align: center;
    font: bold 24px helvetica;
    box-shadow: 0 0 0 1px smokewhite;
    overflow: hidden;
    right: 0;
    top: 0;

}

.peel:before {
   content: "";
    position: absolute;
    top: -44px;
    left: 92px;
    width: 122px;
    height: 95px;
    border-radius: 0;
    -webkit-transform: skew(5deg, 5deg);
    transform: skew(5deg, 5deg);
    -webkit-transform: rotate(-3.5deg) skew(15deg, 5deg);
    transform: rotate(-5deg) skew(25deg, 8deg);
}

.peel span {
    position: absolute;
    top: 6px;
    right: 15px;
    color: white;
	font-size: 16px;
    font-weight: normal;
    font-family: monospace;
    letter-spacing: 1px;
}

.sensor.card .peel {
    background: linear-gradient(to top right, transparent 60%, rgba(0, 0, 0, 0.1) 75%, #6d4592 50%, #6d4592 100%);
}

.sensor.card .peel:before {
    background: linear-gradient(to bottom left, transparent 75%, #8e719f 75%, #aa87cb 100%);
}

.store.card .peel {
    background: linear-gradient(to top right, transparent 60%, rgba(0, 0, 0, 0.1) 75%, #54b6c6 50%, #54b6c6 100%);
}

.store.card .peel:before {
    background: linear-gradient(to bottom left, transparent 75%, #7bc1d1 75%, #80dcec 100%);
}

.product.card .peel {
    background: linear-gradient(to top right, transparent 60%, rgba(0, 0, 0, 0.1) 75%, #ff7800 50%, #ff7800 100%);
}

.product.card .peel:before {
    background: linear-gradient(to bottom left, transparent 75%, #ffa884 75%, #feb394 100%);
}
*/
/* --------------------------*/ 
/*
.btn.btn-sensor {
	color: white !important;
	background: #A10034 !important;
}
*/


