// Variable overrides

// grid dmc
// \node_modules\@coreui\coreui\scss\bootstrap\_variables.scss

//$body-bg:                         red !default;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1600px
);


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1300px,
  xxxl: 1540px
) ;



/*
$dropdown-bg:                       #000000;
$dropdown-link-color:               #ffffff;
*/

